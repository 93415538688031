@tailwind base;
@tailwind components;
@tailwind utilities;

.ais-RefinementList-checkbox {
    @apply mr-2;
}

.ais-RefinementList-count {
    @apply text-gray-400;
}

.ais-RefinementList-count:before {
    content: '(';
}

.ais-RefinementList-count:after {
    content: ')';
}

.ais-RefinementList-list {
    @apply space-y-2 text-gray-700;
    max-height: 500px;
    overflow-y: auto;
}

.ais-RefinementList-label {
    @apply cursor-pointer;
}

.ais-RefinementList-showMore {
    @apply mt-3 w-full text-left text-teal-400 font-bold;
}

.ais-RefinementList-item--selected {
    @apply underline;
}

.ais-SearchBox-form {
    @apply flex mb-2;
}

.ais-SearchBox-input {
    @apply flex-1 mr-2 border px-3 py-1 rounded-lg w-max
}

.ais-SearchBox-submitIcon {
    @apply transform scale-150 mr-2
}

em.ais-Highlight-highlighted {
    @apply font-bold italic;
}

.filter-wrapper {
    min-width: 200px;
}

.ais-CurrentRefinements-category {
    @apply px-2 py-1 bg-teal-400 text-black rounded-full mb-1 text-sm inline-block
}
.ais-CurrentRefinements-delete {
    @apply ml-1
}
.ais-CurrentRefinements-category:not(:first-child) {
    @apply ml-1
}

.ais-InfiniteHits-loadMore {
    @apply bg-teal-400 inline-block mx-auto py-3 rounded-lg w-full font-bold
}

.ais-InfiniteHits-loadMore--disabled {
    @apply hidden
}
.ais-SearchBox-submit {
    @apply hidden;
}

.container {
    @apply max-w-6xl
}

.bg-teal-400 {
    background-color: #16B785;
}

.text-teal-400 {
    color: #16B785;
}
